import { AccidentInsurance, LoanInsurance, SafetyInsurance } from 'APP/interfaces/applications.interfaces';

type AllUpsells = AccidentInsurance[] | LoanInsurance[] | SafetyInsurance[] | undefined;

export function filterCustomerInsuranceIds(upsells: AllUpsells): string[] {
    // Safeguards
    if (!upsells) return [];

    // Properties
    const result: string[] = [];
    const accidentInsurance = upsells.find(item => item.type === 'accident_insurance') as AccidentInsurance;
    const safetyInsurance = upsells.find(item => item.type === 'safety_insurance') as SafetyInsurance;

    if (accidentInsurance?.customer_insurance_id) result.push(accidentInsurance.customer_insurance_id);
    if (safetyInsurance?.customer_insurance_id) result.push(safetyInsurance.customer_insurance_id);

    return result;
}

import { ErrorResponseData } from '@frontend/shared/transport';
import { OptimizelyData } from 'APP/actions/actions.interfaces';
import { States } from 'APP/components/Offers/offerHelpers/offerBusinessConstants';

type BusinessOfferStatus = (typeof States)[keyof typeof States];

export interface IParameters {
    ownership_through_stocks?: boolean;
    information_is_correct?: boolean;
    claims_pep?: boolean;
    controlUsingStocks?: boolean; // this will be deleted once every component use the key 'ownership_through_stocks' instead
    confirmData?: boolean; // same as above
}

export interface IBizRequirements {
    bg_number?: string;
    pg_number?: string;
    clearing_number?: string;
    account_number?: string;
}

export interface IBusinessOfferPageProps {
    status: BusinessOfferStatus;
    parameters: IParameters;
    error: ErrorResponseData | undefined;
    optimizelyData: OptimizelyData;
    bizOfferID: string;
    businessRequirements: IBizRequirements;
}

export const offerPageInitialState: IBusinessOfferPageProps = {
    status: States.STEP_1,
    parameters: {
        claims_pep: false,
    },
    error: undefined,
    optimizelyData: {},
    bizOfferID: '',
    businessRequirements: {},
};

import { createAsyncThunk, GetThunkAPI } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { AcceptanceActions } from 'APP/actions/AcceptanceActions';
import { IFetchAcceptanceResponse, ISelectedInsuranceResponse, OptimizelyData } from 'APP/actions/actions.interfaces';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { OfferConstants } from 'APP/constants/OfferConstants';
import { IAcceptance } from 'APP/interfaces/applications.interfaces';
import { IRootState } from 'APP/interfaces/general.interfaces';
import { InsurancePayment } from 'APP/interfaces/private/offers.interfaces';

import { fetchApplicationsAction } from '../applications/applicationsSliceActions';
import { updateUpsells } from './acceptanceSlice';

export const fetchAcceptance = createAsyncThunk<IFetchAcceptanceResponse, string>(
    'acceptance/get',
    async (id: string, thunkAPI) => {
        try {
            const response = await AcceptanceActions.get(id);
            return response;
        } catch (err: unknown) {
            Sentry.captureException(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

interface IPostAcceptanceArgs {
    acceptance: IAcceptance;
    optimizelyData: OptimizelyData;
    selectedInsurance: ISelectedInsuranceResponse | null;
}

export const postAcceptance = createAsyncThunk(
    'acceptance/post',
    async ({ acceptance, optimizelyData, selectedInsurance }: IPostAcceptanceArgs, thunkAPI) => {
        // if we already have insurance acceptance responses parameters, create parameters object to reuse it then in payload for selectedInruance
        let paramObj;
        for (const item of acceptance.responses) {
            if (item.parameters.length) {
                // Filter out objects with "type": "amount"
                paramObj = item.parameters.filter(param => param.type !== 'amount');
                break; // Exit the loop after the first item that passes the condition
            }
        }

        try {
            const response = await AcceptanceActions.post({
                application_id: acceptance.application_id,
                application_type: ApplicationConstants.TYPE.PRIVATE,
                revision: acceptance.revision,
                parameters: acceptance.parameters,
                extra_parameters: {
                    optimizely: optimizelyData,
                },
                responses: [
                    ...acceptance.responses.map(r => {
                        const obj = { ...r.response, parameters: r.parameters }; // combine response and parameters because response parameters sits in outer object
                        return obj;
                    }),
                    ...(selectedInsurance
                        ? [
                              {
                                  id: selectedInsurance.id,
                                  type: selectedInsurance.type,
                                  ...(paramObj !== undefined && { parameters: paramObj }),
                              },
                          ]
                        : []),
                ],
            });
            thunkAPI.dispatch(fetchApplicationsAction());

            return response;
        } catch (err: unknown) {
            Sentry.captureException(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const postAcceptanceAutogiroAccount = createAsyncThunk(
    'acceptanceAutogiroAccount/post',
    async (
        {
            acceptance,
            responseArray,
        }: {
            acceptance: IAcceptance;
            responseArray: {
                id: string;
                type: string;
            }[];
        },
        thunkAPI
    ) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await AcceptanceActions.post({
                application_id: acceptance.application_id,
                application_type: ApplicationConstants.TYPE.PRIVATE,
                revision: acceptance.revision,
                parameters: {
                    ...acceptance.parameters,
                    sign_challenge_ref: state.acceptanceSlice.bankIdReference,
                },
                extra_parameters: {
                    optimizely: {},
                },
                responses: responseArray,
            });
            thunkAPI.dispatch(fetchApplicationsAction());

            return response;
        } catch (err: unknown) {
            Sentry.captureException(err);
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const getPaymentLinks = createAsyncThunk(
    'fetchPaymentLinks/get',

    async (ids: string[], thunkAPI) => {
        try {
            const { ACCIDENT_INSURANCE, SAFETY_INSURANCE } = OfferConstants.TYPES;
            const queryString = generateQueryString(ids);
            const response = await retryFetching(queryString);
            const payments = response.data;
            const accidentLink = findPaymentURL(payments, 'accidentinsurance');
            const safetyLink = findPaymentURL(payments, 'safetyinsurance');

            if (accidentLink) dispatchInsuranceUpsell(thunkAPI, ACCIDENT_INSURANCE, accidentLink);
            if (safetyLink) dispatchInsuranceUpsell(thunkAPI, SAFETY_INSURANCE, safetyLink);

            return response;
        } catch (error: unknown) {
            Sentry.captureException(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

/** Because the server does not reliabily return the payment links, we need to always call it twice!! */
async function retryFetching(queryString: string): Promise<{ data: InsurancePayment[] }> {
    await AcceptanceActions.getInsurancePayment(queryString);
    await delay(1000);
    const result = await AcceptanceActions.getInsurancePayment(queryString);
    return result;
}

function generateQueryString(ids: string[]): string {
    return ids.map(item => `customer_insurance_id=${item}`).join('&');
}

function findPaymentURL(payments: InsurancePayment[], category: string): string | null {
    const result = payments.find(item => item.product_category === category);

    return result?.payment_registration_url || null;
}

function dispatchInsuranceUpsell(thunkAPI: GetThunkAPI<{ state: IRootState }>, type: string, link: string): void {
    const payment_registration_url = link;
    const payload = { type, payment_registration_url };

    thunkAPI.dispatch(updateUpsells(payload));
}

function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

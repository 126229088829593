import styles from '@frontend/bank-id/BankId/BankId.module.scss';
import Flow from '@frontend/bank-id/BankId/Flow';
import { ACTIONS } from '@frontend/bank-id/enums';
import { EVENTS_CALLBACK } from '@frontend/bank-id/enums/events';
import store from '@frontend/bank-id/feature/store';
import useDevice from '@frontend/bank-id/hooks/useDevice';
import { ErrorResponseData } from '@frontend/shared/transport';
import { Box, SquareButton, TransferIcon } from '@lendoab/aphrodite';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

export interface BankIdProps {
    action: `${ACTIONS}`;
    endpoint: string;
    devMode?: boolean;
    onBack: () => void;
    onRetry?: () => void;
    onCancel?: () => void;
    onError?: (error: ErrorResponseData) => void;
    onEvent?: (data: EVENTS_CALLBACK) => void;
    onVerified: (data: object) => React.ReactNode | null | undefined | void;
}

export default function BankId(props: BankIdProps): ReactElement | null {
    const { devMode = false } = props;
    const [showSSN, setShowSSN] = useState(devMode);
    const device = useDevice();

    // Init store here, so each BankId instance gets its own store.
    // useMemo is used to avoid creating a new store on each render.
    const memoizedStore = useMemo(store, []);

    useEffect(() => {
        setShowSSN(devMode);
    }, [devMode]);

    if (!device) {
        // Puts a placeholder with the correct min-height so the layout doesnt flicker.
        return <Box className={styles.wrapper} />;
    }

    return (
        <ReduxProvider store={memoizedStore}>
            <Box position="relative">
                {devMode ? (
                    <SquareButton
                        borderless
                        position="absolute"
                        icon={<TransferIcon />}
                        style={{ top: 5, right: 5 }}
                        onClick={() => setShowSSN(!showSSN)}
                    />
                ) : null}
                <Box display="flex" className={styles.wrapper} data-testid="flow-wrapper">
                    <Flow {...props} device={device} devMode={showSSN} />
                </Box>
            </Box>
        </ReduxProvider>
    );
}
